<template>
	<body class="auth-wrapper">
		<div class="auth-wrapper auth-bg auth-v2">
			<!-- Brand logo-->
			<nav class="navbar auth-bg ">
				<div class="container-fluid auth-bg p-0">
					<b-link class="brand-logo mx-auto ml-md-4 mb-2">
						<logo-sao-bento />
					</b-link>
				</div>
			</nav>
			<!-- /Brand logo-->

			<!-- Left Text-->
			<b-col lg="8" class="d-none d-lg-flex align-items-center float-left auth-bg">
				<div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
					<b-img fluid :src="imgUrl" alt="Portal do Cliente" />
				</div>
			</b-col>
			<!-- /Left Text-->

			<!-- Login-->
			<b-col lg="4" class="d-flex align-items-center px-2 p-lg-5 py-3 py-lg-0 auth-bg">
				<b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
					<b-card-title title-tag="h2" class="font-weight-bold mb-1 text-center">
						Portal do Cliente
					</b-card-title>

					<!-- form -->
					<validation-observer ref="loginValidation">
						<b-form class="auth-login-form mt-2" @submit.prevent>
							<!-- CPF, CNPJ ou E-mail -->
							<b-form-group label="CPF, CNPJ ou E-mail" label-for="user">
								<validation-provider #default="{ errors }" name="Login" rules="required">
									<b-form-input
										id="login-email-cpf"
										v-model="user"
										:state="errors.length > 0 ? false : null"
										name="user"
										placeholder=""
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- forgot password -->
							<b-form-group>
								<div class="d-flex justify-content-between">
									<label for="login-password">Senha</label>
									<b-link :to="{ name: 'forgot' }">
										<small>Não lembra a senha?</small>
									</b-link>
								</div>
								<validation-provider #default="{ errors }" name="Senha" rules="required">
									<b-input-group
										class="input-group-merge"
										:class="errors.length > 0 ? 'is-invalid' : null"
									>
										<b-form-input
											id="login-password"
											v-model="password"
											:state="errors.length > 0 ? false : null"
											class="form-control-merge"
											:type="passwordFieldType"
											name="login-password"
											placeholder="············"
										/>
										<b-input-group-append is-text>
											<feather-icon
												class="cursor-pointer"
												:icon="passwordToggleIcon"
												@click="togglePasswordVisibility"
											/>
										</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- submit buttons -->
							<b-button type="submit" variant="primary" block @click="validationForm">
								<b-spinner v-if="isLoading" small />
								<span v-else>Entrar</span>
							</b-button>
						</b-form>
					</validation-observer>

					<b-card-text class="mt-1 d-flex flex-wrap">
						<span class="col-12 text-center">Ainda não tem sua conta na São Bento?</span>

						<span @click="goToFirstAccess" class="text-primary mx-auto" style="cursor: pointer"
							>Crie sua conta aqui</span
						>
					</b-card-text>

					<!-- divider -->
					<div class="divider my-2">
						<div class="divider-text">
							ou
						</div>
					</div>

					<!-- social buttons -->
					<div class="auth-footer-btn d-flex justify-content-center">
						<b-button
							variant="outline-primary"
							block
							href="javascript:void(0)"
							@click="handleLoginGoogle"
						>
							<span>
								<svg class="mr-50" width="18px" height="18px" viewBox="0 0 18 18">
									<path
										d="M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727 17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z"
										fill="#4285F4"
									></path>
									<path
										d="M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727 3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182 C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z"
										fill="#34A853"
									></path>
									<path
										d="M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9 C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182 L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727 0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z"
										fill="#FBBC05"
									></path>
									<path
										d="M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455 L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0 2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727 6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z"
										fill="#EA4335"
									></path>
								</svg>
								Iniciar sessão com o Google
							</span>
						</b-button>
					</div>
					<BButton class="mt-2" type="submit" variant="success" @click="goToBoletoFacil" block>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="25"
							height="25"
							fill="currentColor"
							class="bi bi-upc"
							viewBox="0 0 16 16"
						>
							<path
								d="M3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"
							/>
						</svg>
						2ª via de boletos
					</BButton>
				</b-col>
			</b-col>
			<!-- /Login-->
		</div>
	</body>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ptBR from 'vee-validate/dist/locale/pt_BR.json'
import LogoSaoBento from '@/layouts/components/LogoSaoBento.vue'
import {
	BSpinner,
	BCol,
	BLink,
	BFormGroup,
	BFormInput,
	BInputGroupAppend,
	BInputGroup,
	BCardTitle,
	BImg,
	BForm,
	BButton,
	BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { login, loginGoogle } from '@/services/auth'
import store from '@/store'

localize('pt_BR', ptBR)

export default {
	components: {
		BCol,
		BLink,
		BFormGroup,
		BFormInput,
		BInputGroupAppend,
		BInputGroup,
		BCardText,
		BCardTitle,
		BImg,
		BForm,
		BButton,
		BSpinner,
		LogoSaoBento,
		ValidationProvider,
		ValidationObserver,
	},

	mixins: [togglePasswordVisibility],

	data() {
		return {
			isLoading: false,
			status: '',
			password: '',
			user: '',
			sideImg: require('@/assets/images/pages/login/login.svg'),
			// validation rulesimport store from '@/store/index'
			required,
		}
	},

	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
		imgUrl() {
			if (store.state.appConfig.layout.skin === 'dark') {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.sideImg = require('@/assets/images/pages/login/login-dark.svg')
				return this.sideImg
			}
			return this.sideImg
		},
	},

	methods: {
		goToFirstAccess() {
			this.$router.push('/primeiro-acesso')
		},

		goToBoletoFacil() {
			this.$router.push('/boleto-facil')
		},

		async validationForm() {
			this.$refs.loginValidation.validate().then(success => {
				if (success) {
					this.handleLogin()
				}
			})
		},

		async handleLogin() {
			this.isLoading = true

			let { errors } = await login(
				this.user,
				this.password,
				this.$apolloProvider.defaultClient
			)

			this.isLoading = false
			if (errors) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Ops!',
						icon: 'XIcon',
						variant: 'danger',
						text: errors,
					},
				})
			}
		},

		async handleLoginGoogle() {
			this.isLoading = true

			const { errors } = await loginGoogle(this.$apolloProvider.defaultClient)

			this.isLoading = false

			if (errors) {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Ops!',
						icon: 'XIcon',
						variant: 'danger',
						text:
							'Não foi possível prosseguir com o login, utilize a mesma conta de e-mail informada na compra do seu lote!',
					},
				})
			}
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/page-auth.scss';
.brand-logo {
	svg {
		height: 70px;
		width: 160px;
	}
}
html[dir='rtl'] {
	svg.feather {
		transform: rotate(180deg);
	}
}
</style>
